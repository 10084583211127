import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { PageProp } from "."
import { Section } from "../components/atoms/Section"
import { Languages } from "../provider"
import { useLang } from "../components/Header/LanguageToggle/useLang"

const Title: React.FC<{ title: string }> = ({ title }) => {
  return (
    <h4 className="mt-4 text-lg font-bold leading-tight tracking-wider text-vp-blue">
      {title}
    </h4>
  )
}

const Text = {
  [Languages.EN]: (
    <div className="w-full h-full max-w-4xl pb-40 mx-auto leading-relaxed text-gray-800">
      <div className="text-center">
        <Title title="Operational Regulations for Business Activities" />
      </div>
      <br />
      <div className="serif">
        <div className="w-full text-right mb-6 font-bold">
          <p>VantagePoint K.K.</p>
        </div>
        <Title title="Recruitment" />
        <ol
          className="ml-10"
          style={{ listStyle: "decimal", listStylePosition: "outside" }}
        >
          <li>
            We accept all recruitment requests for any type of position in Japan
            and the U.S. However, applications will not be accepted if they
            violates laws, offer working conditions (wages, hours, etc.)
            significantly below usual standards, if there are violations of
            labor-related laws and regulations (Labor Standards Act, Employment
            Security Act, etc.), or if they are submitted by individuals
            associated with criminal organizations.
          </li>
          <li>
            Applications must be submitted directly by the hiring entity or its
            representative. If visiting our office in person is not possible,
            applications may be submitted by mail, phone, or email.{" "}
          </li>
          <li>
            At the time of submission, please clearly specify job duties, wages,
            working hours, and other employment terms in writing or by email. If
            immediate action is required and written or emailed details cannot
            be provided in advance, this information may be provided through
            alternative means.
          </li>
          <li>
            Recruitment applications are subject to an application fee as per
            the fee schedule in the attached table.
          </li>
        </ol>
        <br />

        <Title title="Job Seekers" />
        <ol
          className="ml-10"
          style={{ listStyle: "decimal", listStylePosition: "outside" }}
        >
          <li>
            We accept all job applications for positions in Japan and the U.S.,
            provided they comply with legal regulations.
          </li>
          <li>
            Applicants should submit applications in person. If unable to visit
            in person, please contact us in advance by phone or email.
          </li>
        </ol>

        <Title title="Job Placement Services" />
        <ol
          className="ml-10"
          style={{ listStyle: "decimal", listStylePosition: "outside" }}
        >
          <li>
            For job seekers, we aim to assist in securing employment that aligns
            with their preferences and abilities, in line with the principles of
            free occupational choice under Article 2 of the Employment Security
            Act.
          </li>
          <li>
            For employers, we will assist in finding job seekers who meet the
            qualifications for the desired position.
          </li>
          <li>
            Prior to placement, we will clearly inform job seekers of job
            content, wages, working hours, and other employment conditions in
            writing or by email if preferred. In cases requiring urgent
            placement, this information may be provided by other means if
            written or emailed communication is not possible in advance.
          </li>
          <li>
            Once a recruitment or job application has been received, we are
            committed to handling the placement process with due diligence.
          </li>
          <li>
            To maintain neutrality during labor disputes, we will refrain from
            providing placement services to hiring entities engaged in strikes
            or lockouts.
          </li>
          <li>
            Upon successful placement, an introduction fee will be charged to
            the hiring entity as per the attached fee schedule.
          </li>
        </ol>

        <Title title="Miscellaneous" />
        <ol
          className="ml-10"
          style={{ listStyle: "decimal", listStylePosition: "outside" }}
        >
          <li>
            We will address any complaints from job seekers promptly and
            appropriately, coordinating with employment agencies and other
            relevant entities as necessary.
          </li>
          <li>
            We request that employers and job seekers report the outcomes of
            introductions facilitated by our company. Additionally, for
            positions resulting in indefinite-term employment contracts, we
            request that employers notify us if the new hire resigns within six
            months, excluding cases of dismissal.
          </li>
          <li>
            Personal information obtained from job seekers and employers will be
            handled in compliance with our Personal Information Management
            Regulations.
          </li>
          <li>
            When providing recruitment information through advertisements, we
            will ensure its accuracy and transparency. In addition, in order to
            keep the information accurate and up-to-date, if there is a request
            from a recruiter or job seeker to stop providing the information or
            to correct the content, if confirmed, we will respond without delay
            and take measures to regularly confirm with job seekers or job
            seekers whether the information is up-to-date or to clarify the time
            of the information.
          </li>
          <li>
            We do not engage in discriminatory practices in any aspect of the
            application or placement process based on race, nationality, creed,
            gender, social status, family background, previous occupations, or
            union affiliation.
          </li>
          <li>
            Our services are available for positions across all industries in
            both Japan and the U.S.
          </li>
          <li>
            These operational regulations are subject to compliance with the
            Employment Security Act and relevant laws and notifications. For
            further inquiries, please contact our staff.
          </li>
        </ol>
        <br />
        <div className="text-center pb-6">
          <Title title="Handling of Complaints" />
        </div>
        <p>
          We will respond promptly and sincerely to any complaints from job
          seekers or hiring entities.
        </p>
        <Title title="Contact for Complaints:" />
        <p>Employment Placement Officer</p>
        <p>
          TEL:{" "}
          <a className="text-blue-500 underline" href="tel: +81354134433">
            +81-3-5413-4433
          </a>
        </p>
        <p>
          E-mail:{" "}
          <a
            className="text-blue-500 underline"
            href="mailto: info@vpointcareers.com"
          >
            info@vpointcareers.com
          </a>{" "}
        </p>

        <br />
        <div className="text-center pb-6">
          <Title title="Personal Information Management Regulations" />
        </div>
        <ol
          className="ml-10 article-en"
          style={{ listStyle: "decimal", listStylePosition: "outside" }}
        >
          <li>
            Personal information will be handled by employees engaged in
            employment placement services, with the employment placement officer
            serving as the chief information management officer.
          </li>
          <li>
            The Employment placement officer will provide annual training on the
            handling of personal information to relevant employees and will
            attend a certification course at least once every five years.
          </li>
          <li>
            When personal information disclosure is requested by the individual,
            we will promptly disclose information based on objective facts such
            as relevant qualifications or work experience. If corrections
            (including deletion) are requested, and the request is consistent
            with objective facts, we will promptly make corrections. The
            placement officer will ensure that these procedures are communicated
            to job seekers as needed.
          </li>
          <li>
            Any complaints regarding the handling of personal information will
            be addressed appropriately and in good faith by the responsible
            placement officer.
          </li>
        </ol>

        <br />
        <div className="text-center pb-6">
          <Title title="Upper Limits of Notified Fees" />
        </div>

        <table
          className="w-full border table-fixed align-middle font-sans"
          id="notified-fees-table"
        >
          <thead>
            <tr className="border">
              <th>TYPES AND CONTENTS OF SERVICE</th>
              <th>MAXIMUM AMOUNT AND PAYMENT OF FEE</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Commission for receiving job posting</td>
              <td>JPY 100,000.-</td>
            </tr>
            <tr>
              <td>
                Consultation for employer and employee after receiving their
                application
              </td>
              <td>
                [contingency] Consulting fee shall amount to 100% of the annual
                salary (the amount stipulated in offer letter or notice of
                employment etc.) of employee under the condition that
                VantagePoint achieves successful placement.
                <br />
                Employer shall pay the consulting fee.
              </td>
            </tr>
            <tr>
              <td>
                Professional consultation and advice for employer to facilitate
                recruitment
              </td>
              <td>
                [contingency] Consulting fee shall amount to 100% of the annual
                salary (the amount stipulated in offer letter or notice of
                employment etc.) of employee under the condition that
                VantagePoint achieves successful placement.
                <br />
                Employer shall pay the consulting fee.
              </td>
            </tr>
            <tr>
              <td>
                Special employee search for employer based on specific
                conditions
              </td>
              <td>
                Retainer fee shall be JPY20, 000,000.-.
                <br />
                Daily allowance shall be JPY0.-.
                <br />
                [contingency] Consulting fee shall amount to 100% of the annual
                salary (the amount stipulated in offer letter or notice of
                employment etc.) of employee under the condition that
                VantagePoint achieves successful placement.
                <br />
                Employer shall pay the consulting fee.
              </td>
            </tr>
            <tr>
              <td>
                Professional consultation and advise for employees to facilitate
                employment
              </td>
              <td>
                [contingency]Consulting fee shall amount to 100% of the annual
                salary (the amount stipulated in offer letter or notice of
                employment etc.) of employee under the condition that
                VantagePoint achieves successful placement.
                <br />
                Employer shall pay the consulting fee.
              </td>
            </tr>
          </tbody>
        </table>

        <div className="w-full text-right mb-6">
          <p>The consulting fee above does not include consumption tax.</p>
        </div>

        <div className="text-center pb-6">
          <Title title="Refund Policy" />
        </div>
        <p>
          If a candidate resigns on his or her own will within a short period
          after joining, we offer a refund policy on the introduction fee, based
          on the length of their employment. Should the candidate leave within
          six months of their start date, up to 80% of the fee paid will be
          refunded. However, this refund policy does not apply if the candidate
          is dismissed due to employer circumstances, becomes unable to fulfill
          their duties due to natural disasters, or if employment is terminated
          due to the candidate's passing.
        </p>
        <br />
        <br />
        <br />
        <div className="w-full text-right mb-6">
          <p>VantagePoint K.K.</p>
          <p>Managing Partner Duncan Harrison</p>
        </div>
      </div>
    </div>
  ),
  [Languages.JP]: (
    <div
      className="w-full h-full max-w-4xl pb-40 mx-auto leading-relaxed text-gray-800"
      style={{ font: "Noto Serif JP" }}
    >
      <div className="text-center">
        <Title title="業務の運営に関する規程" />
      </div>
      <br />
      <div className="jp-serif">
        <div className="w-full text-right mb-6 font-bold">
          <p>株式会社バンテージポイント</p>
        </div>
        <Title title="求　　　人" />
        <ol
          className="ml-10"
          style={{ listStyle: "decimal", listStylePosition: "outside" }}
        >
          <li>
            当社は、日本国内及び海外（米国）の全職種に関する限り、いかなる求人の申込みについてもこれを受理します。
            <br />
            ただし、その申込みの内容が法令に違反したり、賃金、労働時間等の労働条件が通常の労働条件と比べて著しく不適当である場合、一定の労働関係法令（労働基準法及び職業安定法等）違反のある場合及び暴力団員などによる求人である場合には受理しません。
          </li>
          <li>
            求人の申込みは、求人者又はその代理人が直接来所されて、お申込みください。直接来所できないときは、郵便、電話、電子メールでも差し支えありません。
          </li>
          <li>
            求人申込みの際には、業務内容、賃金、労働時間、その他の雇用条件をあらかじめ書面の交付、又は電子メール等により明示してください。ただし、紹介の実施について緊急の必要があるためあらかじめ書面の交付又は電子メール等による明示ができないときは、当該明示すべき事項をあらかじめこれらの方法以外の方法により明示してください。
          </li>
          <li>
            求人受付の際には、受付手数料を、別表の料金表に基づき申し受けます。
          </li>
        </ol>
        <br />

        <Title title="求　　　職" />
        <ol
          className="ml-10"
          style={{ listStyle: "decimal", listStylePosition: "outside" }}
        >
          <li>
            当社は、日本国内及び海外（米国）の全職種に関する限り、いかなる求職の申込みについてもこれを受理します。
            <br />
            ただし、その申込みの内容が法令に違反する場合には受理しません。
          </li>
          <li>
            求職申込みは、本人が直接来所されて、お申込みください。直接来社できないときは、事前に電話、または電子メールにてお問合せください。
          </li>
        </ol>

        <Title title="紹　　　介" />
        <ol
          className="ml-10"
          style={{ listStyle: "decimal", listStylePosition: "outside" }}
        >
          <li>
            求職の方には、職業安定法第２条にも規定される職業選択の自由の趣旨を踏まえ、その御希望と能力に応ずる職業に速やかに就くことができるよう極力お世話いたします。
          </li>
          <li>
            求人の方には、その御希望に適合する求職者を極力お世話いたします。
          </li>
          <li>
            紹介に際して、求職の方に、紹介において従事することとなる業務の内容、賃金、労働時間その他の雇用条件をあらかじめ書面の交付又は希望される場合には電子メール等により明示します。ただし、紹介の実施について緊急の必要があるためあらかじめ書面の交付又は電子メール等による明示ができないときは、あらかじめそれらの方法以外の方法により明示を行います。
          </li>
          <li>
            いったん求人、求職の申込みを受けた以上、責任を持って紹介の労をとります。
          </li>
          <li>
            当社は、労働争議に対する中立の立場をとるため、同盟罷業又は作業閉鎖の行われている間は求人者に、紹介を致しません。
          </li>
          <li>
            就職が決定しましたら求人された方から別表の手数料表に基づき、紹介手数料を申し受けます。
          </li>
        </ol>

        <Title title="そ　の　他" />
        <ol
          className="ml-10"
          style={{ listStyle: "decimal", listStylePosition: "outside" }}
        >
          <li>
            当社は、職業安定機関及びその他の職業紹介事業者等と連携を図りつつ、当該事業に係る求職者等からの苦情があった場合は、迅速、適切に対応いたします。
          </li>
          <li>
            当社の行った職業紹介の結果については、求人者、求職者両方から当社に対して、その報告をしてください。
            <br />
            また、当社の職業紹介により期間の定めのない労働契約を締結した求職者が就職から６か月以内に離職（解雇された場合を除く。）したか否かについて、求人者から当社に対して報告してください。
          </li>
          <li>
            当社は、求職者又は求人者から知り得た個人的な情報は個人情報適正管理規程に基づき、適正に取り扱います。
          </li>
          <li>
            当社が広告等により求人等に関する情報を提供するときは、当該情報について虚偽の表示又は誤解を生じさせる表示を行いません。また、当該情報について正確かつ最新の内容に保つため、求人者、求職者等から当該情報について提供の中止や内容の訂正の依頼があった場合や、当社が当該情報が正確、最新でないことを確認した場合は、遅滞なく対応するとともに、求人者又は求職者に対して定期的に当該情報が最新かどうか確認する又は当該情報の時点を明らかにする措置を講じます。
          </li>
          <li>
            当社は、求職者又は求人者に対し、その申込みの受理、面接、指導、紹介等の業務について、人種、国籍、信条、性別、社会的身分、門地、従前の職業、労働組合の組合員であること等を理由として差別的な取扱いは一切致しません。
          </li>
          <li>
            当社の取扱職種の範囲等は、日本国内及び海外（米国）の全職種です。
          </li>
          <li>
            当社の業務の運営に関する規定は、以上のとおりでありますが、当社の業務は、すべて職業安定法関係法令及び通達に基づいて運営されますので、ご不審の点は社員に詳しくおたずねください。
          </li>
        </ol>

        <br />
        <div className="text-center pb-6">
          <Title title="苦情の処理に関する事項" />
        </div>
        <p>
          求職者または求人者からの苦情については、誠意をもって対応いたします。
        </p>
        <Title title="苦情申出先 ● " />
        <p>職業紹介責任者</p>
        <p>
          連絡先{" "}
          <a className="text-blue-500 underline" href="tel: +81354134433">
            +81-3-5413-4433
          </a>
        </p>
        <p>
          Eメール{" "}
          <a
            className="text-blue-500 underline"
            href="mailto: info@vpointcareers.com"
          >
            info@vpointcareers.com
          </a>{" "}
        </p>

        <br />
        <div className="text-center pb-6">
          <Title title="個人情報適正管理規程" />
        </div>
        <ol
          className="ml-10 jo-jp"
          style={{
            listStylePosition: "outside",
          }}
        >
          <li>
            個人情報の取扱者は当社の職業紹介事業従事者とする。個人情報取扱責任者は当社の職業紹介責任者とする。
          </li>
          <li>
            職業紹介責任者は、個人情報を取り扱う第1条に記載する事業所内の従業員に対し、個人情報取り扱いに関する教育・指導を年1回実施することとする。また、職業紹介責任者は、少なくとも5年に1回は職業紹介責任者講習を受講するものとする。
          </li>
          <li>
            取扱者は、個人情報に関して、当該情報に係る本人から情報の開示請求があった場合は、その請求に基づき本人が有する資格や職業経験等客観的事実に基づく情報の開示を遅滞なく行うものとする。さらに、これに基づき訂正（削除を含む。以下同じ。）の請求があった場合に、当該請求が客観的事実に合致するときは、遅滞なく訂正を行うものとする。また、個人情報の開示または訂正に係る取り扱いについて、職業紹介責任者は求職者等への周知に努めることとする。
          </li>
          <li>
            求職者の個人情報に関して、当該情報に係る本人からの苦情の申し出があった場合については、苦情処理責任者は誠意を持って適切な処理をすることとする。なお、個人情報の取り扱いに係る苦情処理の責任者は、当社の職業紹介責任者とする。
          </li>
        </ol>

        <div className="text-center pb-6">
          <Title title="手　数　料　表" />
        </div>

        <table
          className="w-full border table-fixed align-middle font-sans"
          id="notified-fees-table"
        >
          <thead>
            <tr className="border">
              <th>サービスの種類及び内容</th>
              <th>手数料の額及び負担者</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>求人受理時の事務費用</td>
              <td> (上限) 100,000円</td>
            </tr>
            <tr>
              <td>
                求人・求職の申込みを受理した時以降、求人・求職者に提供する紹介のサービス
              </td>
              <td>
                【成功報酬】職業紹介が成功した場合において、当該求職者の就職後１年間で支払われる賃金（内定書や労働条件通知書等に記載されている額）の（上限）100%　※手数料負担者は求人者とします。
              </td>
            </tr>
            <tr>
              <td>
                求人の充足を容易にするための求人者に対する専門的な相談・助言
              </td>
              <td>
                【成功報酬】職業紹介が成功した場合において、当該求職者の就職後１年間で支払われる賃金（内定書や労働条件通知書等に記載されている額）の（上限）100%　※手数料負担者は求人者とします。
              </td>
            </tr>
            <tr>
              <td>特定の条件による特別の求職者の開拓やそのための調査・探索</td>
              <td>
                【着手金】（上限）2,000万円
                <br />
                【活動1日あたり】0円
                <br />
                【成功報酬】職業紹介が成功した場合において、当該求職者の就職後１年間で支払われる賃金（内定書や労働条件通知書等に記載されている額）の（上限）100%　※手数料負担者は求人者とします。
              </td>
            </tr>
            <tr>
              <td>就職を容易にするための求職者に対する専門的な相談・助言</td>
              <td>
                【成功報酬】職業紹介が成功した場合において、当該求職者の就職後１年間で支払われる賃金（内定書や労働条件通知書等に記載されている額）の（上限）100%　※手数料負担者は関係雇用主とします。
              </td>
            </tr>
          </tbody>
        </table>

        <div className="w-full text-right mb-6">
          <p>上記手数料は消費税が含まれていません。</p>
        </div>

        <div className="text-center pb-6">
          <Title title="払戻金に関する事項" />
        </div>
        <p>
          求職者が自己都合等で早期退職に至った場合、入社からの期間に応じて紹介手数料の払戻金制度を設けています。入社後6か月以内に退社した場合支払い済紹介手数料の80%を上限に返金いたします。ただし、求人者の都合により解雇された場合や天災によって職務遂行不可能になった場合或いは求職者の死亡により退職した場合は払戻金制度の適用外となります。
        </p>
        <br />
        <br />
        <br />
        <div className="w-full text-right mb-6">
          <p>株式会社バンテージポイント</p>
          <p>代表取締役　ハリソン・ダンカン・ジョン</p>
        </div>
      </div>
    </div>
  ),
}

const Page: React.FC<PageProp> = ({ location }) => {
  const lang = useLang()
  return (
    <Layout location={location}>
      <SEO title="Business Activities Regulations" />
      <Section>{Text[lang]}</Section>
    </Layout>
  )
}

export default Page
